import { Controller } from "stimulus"
import EasyMDE from 'easymde'
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = [ "textarea" ]

  connect() {
    this.easyMDE = new EasyMDE({
      element: this.textareaTarget,
      spellChecker: false,
      indentWithTabs: false,
      tabSize: 4,
      previewClass: 'preview',
      toolbar: ['bold', 'italic', 'quote', '|', 'unordered-list', 'ordered-list', '|', 'link', 'image', 'table', '|', 'preview', 'side-by-side', 'fullscreen', '|', 'guide'],
      uploadImage: true,
      imageUploadFunction: function(file, successCallback, errorCallback) {
        const url = '/files/direct_uploads'
        const upload = new DirectUpload(file, url)
        upload.create((error, blob) => {
          if (error) {
            blob.delete
            errorCallback(error)
          } else if (blob.byte_size > 2097152) {
            // check file size
            blob.delete
            errorCallback("Image must be under 2 Mb")
          } else if (['image/jpeg', 'image/jpg', 'image/png'].indexOf(blob.content_type) < 0) {
            // check file type
            blob.delete
            errorCallback("Image must be JPEG or PNG format")
          } else {
            const hiddenField = document.createElement('input')
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("value", blob.signed_id);
            hiddenField.name = 'images'
            document.querySelector('form').appendChild(hiddenField)
            successCallback(encodeURI('/files/blobs/' + blob.signed_id + '/' + blob.filename))
          }
        })
      },
      previewRender: function(plainText) {
        let md = require('markdown-it')({
          html: true,
          linkify: true,
          typographer: true
        })
        let result = md.render(plainText)
        return '<div class="prose">' +  result + '</div>';
    	},
    })
  }

}
